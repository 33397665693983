var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c("div", [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c(
              "el-form",
              {
                staticStyle: { width: "300px", "margin-left": "50px" },
                attrs: {
                  "label-position": "right",
                  "label-width": "120px",
                  model: _vm.formInline,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "规则名称：", prop: "feeName" } },
                  [_c("span", [_vm._v(_vm._s(_vm.formInline.feeName))])]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "规则编码：", prop: "feeCode" } },
                  [_c("span", [_vm._v(_vm._s(_vm.formInline.feeCode))])]
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "1250px", "word-break": "break-all" },
                    attrs: { label: "描述：", prop: "description" },
                  },
                  [_c("div", [_vm._v(_vm._s(_vm.formInline.description))])]
                ),
              ],
              1
            ),
            _c(
              "el-form",
              {
                staticStyle: { width: "600px", "margin-left": "50px" },
                attrs: {
                  "label-position": "right",
                  "label-width": "120px",
                  model: _vm.formInline,
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "所属商户：",
                      prop: "operationManagerName",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.formInline.operationName))])]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "操作人：", prop: "operationManagerName" },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formInline.operationManagerName)),
                    ]),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "操作时间：", prop: "updatedTime" } },
                  [_c("span", [_vm._v(_vm._s(_vm.formInline.updatedTime))])]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottom" },
          _vm._l(_vm.source.rules, function (rule) {
            return _c(
              "el-form",
              {
                key: rule.cid,
                staticClass: "b_form",
                attrs: { "label-position": "right", "label-width": "140px" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "生效日期：", prop: "description" } },
                  [_c("span", [_vm._v(_vm._s(rule.cdate))])]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "免费分钟数：",
                      "label-width": "130px",
                      prop: "description",
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(rule.free.freeTime))]),
                    _c("el-switch", {
                      staticClass: "switch",
                      staticStyle: { "margin-left": "20px" },
                      attrs: {
                        disabled: "",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: rule.free.freeTimeIgnore,
                        callback: function ($$v) {
                          _vm.$set(rule.free, "freeTimeIgnore", $$v)
                        },
                        expression: "rule.free.freeTimeIgnore",
                      },
                    }),
                    _vm._v(" 免费分钟数纳入计费时长 "),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "新能源免费分钟数：",
                      "label-width": "140px",
                      prop: "description",
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(rule.free.evFreeTime))]),
                    _c("el-switch", {
                      staticStyle: { "margin-left": "20px" },
                      attrs: {
                        disabled: "",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: rule.free.evFreeTimeIgnore,
                        callback: function ($$v) {
                          _vm.$set(rule.free, "evFreeTimeIgnore", $$v)
                        },
                        expression: "rule.free.evFreeTimeIgnore",
                      },
                    }),
                    _vm._v(" 免费分钟数纳入计费时长 "),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "新能源折扣系数：",
                      "label-width": "130px",
                      prop: "description",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(rule.free.evDiscount))])]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "残疾人车免费分钟数：",
                      "label-width": "160px",
                      prop: "description",
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(rule.free.iwdFreeTime))]),
                    _c("el-switch", {
                      staticStyle: { "margin-left": "20px" },
                      attrs: {
                        disabled: "",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: rule.free.iwdFreeTimeIgnore,
                        callback: function ($$v) {
                          _vm.$set(rule.free, "iwdFreeTimeIgnore", $$v)
                        },
                        expression: "rule.free.iwdFreeTimeIgnore",
                      },
                    }),
                    _vm._v(" 免费分钟数纳入计费时长 "),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "残疾人车折扣系数：",
                      "label-width": "150px",
                      prop: "description",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(rule.free.iwdDiscount))])]
                ),
                _c(
                  "el-form-item",
                  [
                    _c("el-switch", {
                      staticClass: "switchShow",
                      attrs: {
                        disabled: "",
                        "active-value": 1,
                        "active-text": "无牌车计费",
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: rule.special.noplate,
                        callback: function ($$v) {
                          _vm.$set(rule.special, "noplate", $$v)
                        },
                        expression: "rule.special.noplate",
                      },
                    }),
                    _c("el-switch", {
                      staticClass: "switchShow",
                      attrs: {
                        disabled: "",
                        "active-value": 1,
                        "active-text": "警车计费",
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: rule.special.police,
                        callback: function ($$v) {
                          _vm.$set(rule.special, "police", $$v)
                        },
                        expression: "rule.special.police",
                      },
                    }),
                    _c("el-switch", {
                      staticClass: "switchShow",
                      attrs: {
                        disabled: "",
                        "active-value": 1,
                        "active-text": "军车计费",
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: rule.special.army,
                        callback: function ($$v) {
                          _vm.$set(rule.special, "army", $$v)
                        },
                        expression: "rule.special.army",
                      },
                    }),
                    _c("el-switch", {
                      staticClass: "switchShow",
                      attrs: {
                        disabled: "",
                        "active-value": 1,
                        "active-text": "工程车计费",
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: rule.special.embassy,
                        callback: function ($$v) {
                          _vm.$set(rule.special, "embassy", $$v)
                        },
                        expression: "rule.special.embassy",
                      },
                    }),
                    _c("el-switch", {
                      staticClass: "switchShow",
                      attrs: {
                        disabled: "",
                        "active-value": 1,
                        "active-text": "救护车计费",
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: rule.special.ambulance,
                        callback: function ($$v) {
                          _vm.$set(rule.special, "ambulance", $$v)
                        },
                        expression: "rule.special.ambulance",
                      },
                    }),
                    _c("el-switch", {
                      staticClass: "switchShow",
                      attrs: {
                        disabled: "",
                        "active-value": 1,
                        "active-text": "应急车计费",
                        "inactive-value": 0,
                        "active-color": "#527BFF",
                        "inactive-color": "#A3A3A3",
                      },
                      model: {
                        value: rule.special.selfEntry,
                        callback: function ($$v) {
                          _vm.$set(rule.special, "selfEntry", $$v)
                        },
                        expression: "rule.special.selfEntry",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "24小时最高收费：",
                      "label-width": "130px",
                      prop: "description",
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(rule.limit24hour))]),
                    _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: {
                          "true-label": 1,
                          "false-label": 0,
                          disabled: "true",
                        },
                        model: {
                          value: rule.unitUp,
                          callback: function ($$v) {
                            _vm.$set(rule, "unitUp", $$v)
                          },
                          expression: "rule.unitUp",
                        },
                      },
                      [_vm._v("出场时不满计费单元部分补齐计费单元收费")]
                    ),
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: "工作日计费规则：" } }),
                _vm._l(rule.details, function (detail, index) {
                  return _c(
                    "div",
                    { key: detail.cid },
                    [
                      _vm.templateId == "104"
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: "时段" + (index + 1) + "：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(detail.start + "-" + detail.end)
                                  ),
                                ]),
                                detail.items[0].type != 1
                                  ? _c("span", { staticClass: "feeDis" }, [
                                      _vm._v(
                                        "时段最高金额：" + _vm._s(detail.limit)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._l(detail.items, function (item, index) {
                              return _c(
                                "el-form-item",
                                {
                                  key: item.cid,
                                  attrs: {
                                    label: "区间" + (index + 1) + "：",
                                    prop: "description",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "feeDis" }, [
                                    _vm._v(
                                      "计费方式： " +
                                        _vm._s(
                                          item.type == 2
                                            ? "周期"
                                            : item.type == 0
                                            ? "基础时段"
                                            : "次"
                                        )
                                    ),
                                  ]),
                                  _c("span", { staticClass: "feeDis" }, [
                                    _vm._v(_vm._s(item.segment) + "分钟"),
                                  ]),
                                  _c("span", { staticClass: "feeDis" }, [
                                    _vm._v(
                                      _vm._s(item.unit) +
                                        "元/" +
                                        _vm._s(
                                          item.type == 1
                                            ? "次"
                                            : item.period + "分钟"
                                        )
                                    ),
                                  ]),
                                ]
                              )
                            }),
                          ]
                        : [
                            _c(
                              "el-form-item",
                              { attrs: { label: "时段" + (index + 1) + "：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(detail.start + "-" + detail.end)
                                  ),
                                ]),
                                _c("span", { staticClass: "feeDis" }, [
                                  _vm._v(
                                    "时段最高金额：" + _vm._s(detail.limit)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._l(detail.items, function (item, index1) {
                              return _c(
                                "el-form-item",
                                {
                                  key: item.cid,
                                  attrs: {
                                    label:
                                      detail.items.length == 1
                                        ? ""
                                        : index1 == 0 ||
                                          index1 == detail.items.length - 1
                                        ? index1 == 0
                                          ? `${item.segment}分钟内`
                                          : `${
                                              detail.items[index1 - 1]
                                                .rangeRule ||
                                              detail.items[index1 - 1].segment
                                            }分钟后`
                                        : `${
                                            detail.items[index1 - 1]
                                              .rangeRule ||
                                            detail.items[index1 - 1].segment
                                          }分钟 - ${item.rangeRule}分钟`,
                                    prop: "description",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "feeDis" }, [
                                    _vm._v(
                                      "计费方式： " +
                                        _vm._s(
                                          item.type == 2
                                            ? "周期"
                                            : item.type == 0
                                            ? "基础时段"
                                            : "次"
                                        )
                                    ),
                                  ]),
                                  _c("span", { staticClass: "feeDis" }, [
                                    _vm._v(
                                      _vm._s(item.unit) +
                                        "元/" +
                                        _vm._s(
                                          item.type == 1
                                            ? "次"
                                            : item.period + "分钟"
                                        )
                                    ),
                                  ]),
                                ]
                              )
                            }),
                          ],
                    ],
                    2
                  )
                }),
                rule.holiday && rule.holiday.length
                  ? _c(
                      "div",
                      [
                        _c("el-form-item", {
                          attrs: { label: "非工作日计费规则：" },
                        }),
                        _vm._l(rule.holiday, function (detail, index) {
                          return _c(
                            "div",
                            { key: detail.cid },
                            [
                              _vm.templateId == "104"
                                ? [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "时段" + (index + 1) + "：",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              detail.start + "-" + detail.end
                                            )
                                          ),
                                        ]),
                                        detail.items[0].type != 1
                                          ? _c(
                                              "span",
                                              { staticClass: "feeDis" },
                                              [
                                                _vm._v(
                                                  "时段最高金额：" +
                                                    _vm._s(detail.limit)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._l(
                                      detail.items,
                                      function (item, index) {
                                        return _c(
                                          "el-form-item",
                                          {
                                            key: item.cid,
                                            attrs: {
                                              label:
                                                "区间" + (index + 1) + "：",
                                              prop: "description",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "feeDis" },
                                              [
                                                _vm._v(
                                                  "计费方式： " +
                                                    _vm._s(
                                                      item.type == 2
                                                        ? "周期"
                                                        : item.type == 0
                                                        ? "基础时段"
                                                        : "次"
                                                    )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "feeDis" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.segment) + "分钟"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "feeDis" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.unit) +
                                                    "元/" +
                                                    _vm._s(
                                                      item.type == 1
                                                        ? "次"
                                                        : item.period + "分钟"
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ]
                                : [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "时段" + (index + 1) + "：",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              detail.start + "-" + detail.end
                                            )
                                          ),
                                        ]),
                                        _c("span", { staticClass: "feeDis" }, [
                                          _vm._v(
                                            "时段最高金额：" +
                                              _vm._s(detail.limit)
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._l(
                                      detail.items,
                                      function (item, index1) {
                                        return _c(
                                          "el-form-item",
                                          {
                                            key: item.cid,
                                            attrs: {
                                              label:
                                                detail.items.length == 1
                                                  ? ""
                                                  : index1 == 0 ||
                                                    index1 ==
                                                      detail.items.length - 1
                                                  ? index1 == 0
                                                    ? `${item.segment}分钟内`
                                                    : `${
                                                        detail.items[index1 - 1]
                                                          .rangeRule ||
                                                        detail.items[index1 - 1]
                                                          .segment
                                                      }分钟后`
                                                  : `${
                                                      detail.items[index1 - 1]
                                                        .rangeRule ||
                                                      detail.items[index1 - 1]
                                                        .segment
                                                    }分钟 - ${
                                                      item.rangeRule
                                                    }分钟`,
                                              prop: "description",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "feeDis" },
                                              [
                                                _vm._v(
                                                  "计费方式： " +
                                                    _vm._s(
                                                      item.type == 2
                                                        ? "周期"
                                                        : item.type == 0
                                                        ? "基础时段"
                                                        : "次"
                                                    )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "feeDis" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.unit) +
                                                    "元/" +
                                                    _vm._s(
                                                      item.type == 1
                                                        ? "次"
                                                        : item.period + "分钟"
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "非工作日计费规则：" } },
                          [_vm._v("同工作日计费规则")]
                        ),
                      ],
                      1
                    ),
              ],
              2
            )
          }),
          1
        ),
        _c(
          "div",
          { staticStyle: { "text-align": "center" } },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 查看计费规则 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }